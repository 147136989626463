/* eslint-disable */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import { Briefcase } from "react-feather";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components/macro";
import {
  CustomDatatableV2,
  CenterWithColor,
  StyledLink,
} from "../../../styles";
import {
  makeDefaultTableOptions,
  getSrcTwo,
} from "../../../../../storage/helpers";
import {
  DEFINITIONS,
  ENGAGEMENT_TYPES,
  returnDefinitionByEngagementType,
} from "../../../../../storage/constants";
import NoImage from "../../../../../assets/images/empty-small.jpg";
import TableTitle from "../../../../../components/Table/TableTitle";
import SubmissionTypeChip from "../../../home/retail/SubmissionTypeChip";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@material-ui/core";

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    margin-right: 8px;
    width: ${(props) => (props.isWide === "yes" ? 50 : 40)}px;
    height: ${(props) => (props.isWide === "yes" ? 35 : 40)}px;
    height: 40px;
    width: 40px;
    object-fit: contain;
    border-radius: 4px;
  }
`;

const getTableData = (companies, engagementType) => {
  if (engagementType === ENGAGEMENT_TYPES.ALL) {
    return companies.map((c) => {
      return {
        ...c,
        comps: c.assigned_completions + c.follower_completions,
        subs: c.assigned_submissions + c.follower_submissions,
        passRate:
          c.assigned_submissions + c.follower_submissions < 1
            ? 0
            : Math.floor(
                ((c.assigned_completions + c.follower_completions) /
                  (c.assigned_submissions + c.follower_submissions)) *
                  100,
              ),
      };
    });
  }

  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    return companies.map((c) => {
      return {
        ...c,
        comps: c.assigned_completions,
        subs: c.assigned_submissions,
        passRate:
          c.assigned_submissions < 1
            ? 0
            : Math.floor(
                (c.assigned_completions / c.assigned_submissions) * 100,
              ),
      };
    });
  }

  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    return companies.map((c) => {
      return {
        ...c,
        comps: c.follower_completions,
        subs: c.follower_submissions,
        passRate:
          c.follower_submissions < 1
            ? 0
            : Math.floor(
                (c.follower_completions / c.follower_submissions) * 100,
              ),
      };
    });
  }
};

const CompanyTable = ({ companies, engagementType }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("company-engagement");
  options.sortOrder = {
    name: "comps",
    direction: "desc",
  };
  options.rowsPerPage = 20;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = getTableData(companies, engagementType);

  const columns = [
    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Company",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val, tableMeta) => (
          <FlexDiv>
            <LazyLoadImage
              src={tableMeta.rowData[5]}
              effect="opacity"
              style={{
                height: 30,
                width: 40,
                objectFit: "contain",
                marginRight: 4,
              }}
              onError={(event) => (event.target.src = NoImage)}
            />
            <StyledLink
              to={`/vendor-reports/company/${tableMeta.rowData[0]}`}
              value={val}
              CustomIcon={Briefcase}
            />
          </FlexDiv>
        ),
      },
    },
    {
      name: "subs",
      label: "Submissions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("submissions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "comps",
      label: "Completions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("completions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "passRate",
      label: "Pass Rate",
      options: {
        filter: true,
        sort: true,
        hint: DEFINITIONS.passRate,
        customBodyRender: (value) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{`${value}%`}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "logo",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
  ];

  return (
    <CustomDatatableV2
      title={
        <div style={{ display: "flex", gap: 6 }}>
          <TableTitle Icon={Briefcase} title="Companies engaging with module" />
          <SubmissionTypeChip isVendor small submissionType={engagementType} />
        </div>
      }
      data={tableData}
      options={options}
      columns={columns}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default CompanyTable;

CompanyTable.propTypes = {
  companies: PropTypes.array.isRequired,
  engagementType: PropTypes.string.isRequired,
};
