/* eslint-disable */
import React, { useState } from "react";
import { Checkbox, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import API from "../../../axios/instances/API";
import { setRefresh, setToken } from "../../../storage/helpers";
import { Alert, Button } from "../../private/styles";
import { Wrapper } from "../auth/styles";
import DashboardSignupHeader from "./DashboardSignupHeader";
import {
  getRegisterErrorMessage,
  getRegisterInfo,
  REGISTER_TYPES,
} from "./helpers";
import * as amplitude from "@amplitude/analytics-browser";

const TOC_LINK = "https://www.withspiffy.com/terms-and-conditions/";

const StyledLink = styled.a`
  display: inline;
  color: #22597f;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: 500;
`;

const TitleText = styled.p`
  font-size: 13px;
  font-weight: 600;
  line-height: 1.3;
  width: 100%;
  margin-bottom: 4px;
`;

const FlexItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee50;
  padding: 4px;

  p {
    font-size: 13px;
    color: black;
    font-weight: 400;
  }

  span {
    font-size: 13px;
    font-weight: 500;
  }
`;

const ConfirmAndRegister = ({ signupState, dispatch, ACTIONS }) => {
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);
  const history = useHistory();

  const { companyInfo, registerInfo, cantFindCompany, createCompanyInfo } =
    signupState;

  const handleRegister = async () => {
    setIsSubmitting(true);
    try {
      const { registerType, sendObj } = getRegisterInfo(signupState);

      await API.post("dashboard/signup/", sendObj);
      amplitude.track("New User Registered");

      // if created new company , you can sign in right away
      if (
        [
          REGISTER_TYPES.RETAIL_ADMIN_NEW_COMPANY,
          REGISTER_TYPES.VENDOR_ADMIN_NEW_COMPANY,
        ].includes(registerType)
      ) {
        try {
          await handleLogUserIn(sendObj.email, sendObj.password);
          setIsSubmitting(false);
          return undefined;
        } catch (error) {
          dispatch({ type: ACTIONS.FINISH_REGISTER, payload: registerType });
        }
      }
      setIsSubmitting(false);
      dispatch({ type: ACTIONS.FINISH_REGISTER, payload: registerType });
    } catch (error) {
      setIsSubmitting(false);
      const errorMsg = getRegisterErrorMessage(error);
      setError(errorMsg);
    }
  };

  const handleLogUserIn = async (email, password) => {
    const { data } = await API.post("/dashboard/auth/", {
      username: email,
      password: password,
    });
    setToken(data.token);
    setRefresh(data.refresh);
    return history.push("/home/");
  };

  return (
    <Wrapper>
      <DashboardSignupHeader
        handleBack={() => dispatch({ type: ACTIONS.RETURN_FROM_CONFIRM })}
        title="Confirm & Register"
        subtitle="Double check information and finalize registration"
      />
      <div
        style={{
          width: "100%",
          padding: "8px 4px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <TitleText>User Info</TitleText>

        <FlexItem>
          <p>Email/Username:</p>
          <span>{registerInfo?.email}</span>
        </FlexItem>
        <FlexItem>
          <p>Name:</p>
          <span>
            {registerInfo?.firstName} {registerInfo?.lastName}
          </span>
        </FlexItem>
        <FlexItem>
          <p>Password:</p>
          <span>{registerInfo?.password.replaceAll(/./g, "*")}</span>
        </FlexItem>
        <FlexItem>
          <p>Date Of Birth:</p>
          <span>{registerInfo.dob.toDateString()}</span>
        </FlexItem>
        <FlexItem>
          <p>Country Of Residence:</p>
          <span>{registerInfo?.country}</span>
        </FlexItem>
        <div style={{ height: 20 }} />
        {cantFindCompany && (
          <>
            <TitleText>Creating New Company</TitleText>
            <FlexItem>
              <p>Company Name:</p>
              <span>{createCompanyInfo?.companyName}</span>
            </FlexItem>

            <FlexItem>
              <p>Company Type:</p>
              <span style={{ textTransform: "capitalize" }}>
                {createCompanyInfo?.type === "VENDOR" ? "Supplier" : "Employer"}
              </span>
            </FlexItem>
            <FlexItem>
              <p>Industry:</p>
              <span style={{ textTransform: "capitalize" }}>
                {createCompanyInfo?.companyVertical?.name}
              </span>
            </FlexItem>
            <FlexItem>
              <p>Country:</p>
              <span style={{ textTransform: "capitalize" }}>
                {createCompanyInfo?.country?.name}
              </span>
            </FlexItem>
          </>
        )}
        {!cantFindCompany && (
          <>
            <TitleText>Joining Company</TitleText>
            <FlexItem>
              <p>Company Name:</p>
              <span style={{ textTransform: "capitalize" }}>
                {companyInfo?.name}
              </span>
            </FlexItem>
            <FlexItem>
              <p>Company Type:</p>
              <span style={{ textTransform: "capitalize" }}>
                {companyInfo?.is_retail ? "Employer" : "Supplier"}
              </span>
            </FlexItem>
            <FlexItem>
              <p>Account Type:</p>
              <span style={{ textTransform: "capitalize" }}>
                {companyInfo?.accessLevel}
              </span>
            </FlexItem>
            {Boolean(companyInfo?.location) && (
              <FlexItem>
                <p>Location:</p>
                {companyInfo?.location.length === 1 ? (
                  <span>
                    {companyInfo?.location[0]?.address?.line1},{" "}
                    {companyInfo?.location[0]?.address?.city?.name},{" "}
                    {companyInfo?.location[0]?.address?.state?.name}
                  </span>
                ) : (
                  <span>Multiple Locations</span>
                )}
              </FlexItem>
            )}
          </>
        )}
      </div>
      <div
        style={{
          width: "100%",
          borderRadius: 4,
          padding: 2,
          background: "#EEEEEE44",
          marginTop: 12,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Checkbox checked={checked} onClick={() => setChecked((p) => !p)} />
        <span style={{ fontSize: 12, color: "black" }}>
          I agree with the
          <StyledLink target="_blank" href={TOC_LINK}>
            terms and conditions
          </StyledLink>
          of Spiffy
        </span>
      </div>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        mt={6}
        startIcon={isSubmitting ? <CircularProgress size={14} /> : null}
        disabled={isSubmitting || !checked}
        onClick={handleRegister}
      >
        Confirm & Register
      </Button>
      {error && (
        <Alert
          severity="error"
          onClose={() => setError(null)}
          style={{ marginTop: 8 }}
        >
          <p style={{ fontWeight: "600" }}>Register Error</p>
          <span>{error}</span>
        </Alert>
      )}
    </Wrapper>
  );
};

export default ConfirmAndRegister;

ConfirmAndRegister.propTypes = {
  signupState: PropTypes.object.isRequired,
};
