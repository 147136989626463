import useSWR from "swr";
import API from "../../../axios/instances/API";

const BASE_URL = "/dashboard/analytics/vendor-reports/quiz-breakdown/?quiz_id=";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useVendorModuleReport = (id) => {
  const { data, error, mutate } = useSWR(`${BASE_URL}${id}`, fetcher);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useVendorModuleReport;
