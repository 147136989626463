/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { Paper, Typography } from "@material-ui/core";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import { Award, BarChart2, Columns, Percent, PieChart } from "react-feather";
import { DescriptorText, OverSizedText } from "../../home/styles";
import {
  getAverageScore,
  getDaysSinceLastSubmission,
  getTotals,
} from "./utils";
import { addCommas } from "../../../../storage/helpers";
import useTeamMemberBreakdownInfo from "../../../../swr/hooks/RetailReports/singleTeamMemberV2/useTeamMemberBreakdownInfo";
import PerformanceStats from "./PerformaceStats";
import TimeStats from "./TimeStats";
import SubmissionTable from "./SubmissionTable";
import { Lock } from "react-feather";
import FunLoader from "../../../../components/Loaders/FunLoader";

const Wrapper = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: auto;
  overflow: hidden;
  gap: 16px;
`;

const InfoWrapper = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 16px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;

const InfoPaper = styled(Paper)`
  padding: 20px;
  flex: 0.5;
  min-height: 150px;
  text-align: left;
  display: flex;
  border-radius 6px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: ${(props) => {
    if (props.subtype === ENGAGEMENT_TYPES.ASSIGNED) {
      return "0px 0px 2px 2px rgba(0, 0, 250, .4)";
    }
    if (props.subtype === ENGAGEMENT_TYPES.EXTRACREDIT) {
      return "0px 0px 1px 2px gold";
    }
  }};

  svg {
    height: 26; 
    width: 26;
    color: black;
  }
`;

const InfoInner = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  text-align: center;
`;

const FakeReportPaper = styled(Paper)`
  padding: 20px;
  flex: 0.5;

  text-align: left;
  display: flex;
  border-radius 6px;
  display: flex;
  flex-direction: column;
`;

const FakeReport = ({ title }) => (
  <FakeReportPaper>
    <Typography variant="h5" mb={2} mt={1}>
      {title}
    </Typography>
    <div style={{ height: 12 }} />
    <div
      style={{
        width: "100%",
        height: 300,
        background: "#eeeeee",
        borderRadius: 6,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <Lock height={15} width={15} color="firebrick" />
      <span style={{ color: "slategrey", fontSize: 14 }}>
        <em>Premium feature</em>
      </span>
    </div>
  </FakeReportPaper>
);

const RestrictedReportUi = () => (
  <div
    style={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 16,
    }}
  >
    <InfoWrapper>
      <InfoPaper>
        <InfoInner>
          <Lock color="firebrick" height={13} width={13} />
          <span
            style={{ color: "slategrey", fontStyle: "italic", fontSize: 11 }}
          >
            Premium Feature
          </span>
        </InfoInner>
      </InfoPaper>
      <InfoPaper>
        <InfoInner>
          <Lock color="firebrick" height={13} width={13} />
          <span
            style={{ color: "slategrey", fontStyle: "italic", fontSize: 11 }}
          >
            Premium Feature
          </span>
        </InfoInner>
      </InfoPaper>
      <InfoPaper>
        <InfoInner>
          <Lock color="firebrick" height={13} width={13} />
          <span
            style={{ color: "slategrey", fontStyle: "italic", fontSize: 11 }}
          >
            Premium Feature
          </span>
        </InfoInner>
      </InfoPaper>
      <InfoPaper>
        <InfoInner>
          <Lock color="firebrick" height={13} width={13} />
          <span
            style={{ color: "slategrey", fontStyle: "italic", fontSize: 11 }}
          >
            Premium Feature
          </span>
        </InfoInner>
      </InfoPaper>
    </InfoWrapper>
    <FakeReport title="Performance Stats" />
    <FakeReport title="Submission Stats" />
    <FakeReport title="All Submissions" />
  </div>
);

const getActiveSubmissions = (submissions, engagementType) => {
  if (!submissions || submissions.length === 0) return [];

  if (engagementType === ENGAGEMENT_TYPES.ALL) {
    return submissions;
  }

  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    return submissions.filter((sub) => sub.source === SOURCE_TYPES.ASSIGNED);
  }

  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    return submissions.filter(
      (sub) => sub.source === SOURCE_TYPES.EXTRA_CREDIT,
    );
  }

  return [];
};

const SOURCE_TYPES = {
  EXTRA_CREDIT: "extra_credit",
  ASSIGNED: "assigned",
};

const RightSideRestricted = ({
  engagementType,
  id,
  fullName,
  breakdownData,
  breakdownLoading,
  breakdownError,
}) => {
  if (breakdownLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: 400,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 8,
        }}
      >
        <FunLoader />
        <span style={{ color: "darkgrey", fontStyle: "italic" }}>
          loading...
        </span>
      </div>
    );
  }

  if (breakdownError) return <RestrictedReportUi />;

  const { list: submissions, summary } = breakdownData.submissions;
  const activeSubmissions = getActiveSubmissions(submissions, engagementType);

  const progress = breakdownData?.progress?.summary;

  const {
    totalUniqueModules,
    mostAttempts,
    passedFirstTry,
    firstTryPercentage,
  } = getTotals(activeSubmissions);

  const daysSinceLast =
    activeSubmissions.length > 0
      ? getDaysSinceLastSubmission(activeSubmissions)
      : 0;

  const totalSubmission = activeSubmissions.length;

  const totalCompletions = activeSubmissions.reduce((total, acc) => {
    if (acc.passed) return (total += 1);
    return (total += 0);
  }, 0);

  const { averageScore, passRate } = getAverageScore(activeSubmissions);

  return (
    <Wrapper>
      <InfoWrapper>
        <InfoPaper subtype={engagementType}>
          <div
            style={{
              height: 32,
              width: 32,
              borderRadius: 8,
              background: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 4,
            }}
          >
            <BarChart2 />
          </div>
          <DescriptorText>Submissions </DescriptorText>
          <OverSizedText>{addCommas(totalSubmission)}</OverSizedText>
        </InfoPaper>
        <InfoPaper subtype={engagementType}>
          <div
            style={{
              height: 32,
              width: 32,
              borderRadius: 8,
              background: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 4,
            }}
          >
            <PieChart />
          </div>

          <DescriptorText>Completions</DescriptorText>
          <OverSizedText>{addCommas(totalCompletions)}</OverSizedText>
        </InfoPaper>
        <InfoPaper subtype={engagementType}>
          <div
            style={{
              height: 32,
              width: 32,
              borderRadius: 8,
              background: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 4,
            }}
          >
            <Percent />
          </div>
          <DescriptorText>Pass Rate</DescriptorText>
          <OverSizedText>{passRate}%</OverSizedText>
        </InfoPaper>
        <InfoPaper
          subtype={engagementType}
          style={{
            background: "#eeeeee",
          }}
        >
          <div
            style={{
              height: 32,
              width: 32,
              borderRadius: 8,
              background: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 4,
            }}
          >
            <Award color="slategrey" height={22} width={22} />
          </div>
          <DescriptorText style={{ color: "slategray" }}>
            Spiffy Score
          </DescriptorText>
          <OverSizedText style={{ color: "black" }}>
            {addCommas(breakdownData?.spiffy_score?.overall_score)}
          </OverSizedText>
        </InfoPaper>
      </InfoWrapper>
      <PerformanceStats
        name={fullName}
        firstTryPercentage={firstTryPercentage}
        passedFirstTry={passedFirstTry}
        totalUniqueModules={totalUniqueModules}
        totalCompletions={totalCompletions}
        totalSubmission={totalSubmission}
        averageScore={averageScore}
        engagementType={engagementType}
        progress={progress}
      />
      <TimeStats
        submissions={activeSubmissions}
        daysSinceLast={daysSinceLast}
        mostAttempts={mostAttempts}
        engagementType={engagementType}
      />
      <SubmissionTable
        submissions={activeSubmissions}
        engagementType={engagementType}
      />
    </Wrapper>
  );
};

export default RightSideRestricted;
